import { graphql, Link, PageProps } from "gatsby";
import React from "react"
import { NoneHomeStorePageDataType } from "../../../types/pages";
import Hero from "../../hero";
import Layout from "../../layout";
import SEO from "../../seo";

import "./ql-text-editor-style.css";

export default function StorePage({ data: { pageData } }: PageProps<NoneHomeStorePageDataType>) {
  const productDates = {
    first_publication_date: pageData?.date || (new Date()).toISOString(),
    last_publication_date: pageData?.modified || (new Date()).toISOString(),
  };

  return (
    <Layout>
      <SEO
        title={pageData?.title || ""}
        description={(pageData.content || "")?.substring(0, 50)}
        banner={pageData?.featured_media || ""}
        pathname={`/${pageData?.slug || ""}/`}
        node={productDates}
      />

      <div className="container">

        {
          !!pageData?.featured_media
            ? (
              <Hero
                src={pageData?.featured_media || ""}
                alt={pageData?.title || ""}
              />
            )
            : (
              <section className="py-5 bg-light">
                <div className="container">
                  <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
                    <div className="col-lg-6">
                      <h1 className="h2 text-uppercase mb-0">
                        {pageData?.title}
                      </h1>
                    </div>
                    <div className="col-lg-6 text-lg-end">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-lg-end mb-0 px-0 bg-light">
                          <li className="breadcrumb-item">
                            <Link
                              to={"/"}
                              className="text-dark"
                            >
                              Home
                            </Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            {pageData?.title}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </section>
            )
        }

        <div
          dangerouslySetInnerHTML={{ __html: pageData.content || "" }}
          className={"custom__page py-5"}
        />

      </div>

    </Layout>
  )
}


export const pageQuery = graphql`
  query ($id: String!) {
    pageData: zzPage(
      id: {eq: $id}
    ) {
      id
      title
      type
      date
      modified
      content
      featured_media
    }
  }
`;